<template>
  <v-container id="product" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Product Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Product Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Product Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="isFlashMessage" :timeout="4000" top>
      <span>{{ flashMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="isFlashMessage = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="products"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Product' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Product</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  small
                  @click="dialogDelete = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="8" v-if="item.product_variants.length == 0">
          No Variants
        </td>
        <td
          colspan="8"
          v-if="item.product_variants.length > 0"
          style="padding: 25px"
        >
          <v-row v-for="variant in item.product_variants" :key="variant._id">
            <span>
              <b>{{
                variant.variant_quantity_per_pack +
                  "x " +
                  variant.variant_volume_value +
                  " " +
                  variant.variant_volume_type +
                  " " +
                  variant.variant_type
              }}</b> </span
            >&nbsp;&nbsp;&nbsp;&nbsp;
            <!-- <span style="width: 150px">
              <b>Total Quantity</b> : {{ variant.variant_quantity }}
            </span>
            <span style="width: 150px"
              ><b>Actual Price</b> : {{ variant.variant_actual_price }}
            </span>
            <span style="width: 150px">
              <b>Retail Price</b> : {{ variant.variant_retail_price }}
            </span> -->
            <span> <b>SKU Number</b> : {{ variant.variant_sku_number }} </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span> <b>UPC Number</b> : {{ variant.variant_upc_number }} </span>
          </v-row>
        </td>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.store_name`]="{ item }">
        <div v-if="item.store_name[0] != null">
          {{ item.store_name[0].name }}
        </div>
      </template>
      <template v-slot:[`item.category_name`]="{ item }">
        <div v-if="item.category_name[0] != null">
          {{ item.category_name[0].name }}
        </div>
      </template>
      <template v-slot:[`item.sub_category_name`]="{ item }">
        <div v-if="item.sub_category_name[0] != null">
          {{ item.sub_category_name[0].name }}
        </div>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div class="truncate">{{ item.description }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Product',
            params: { id: item._id }
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Product',
            params: { id: item._id, action: 'view' }
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon v-if="modulePermission.Delete" @click="deleteProduct(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      imageUrl: process.env.VUE_APP_Image_Link,
      singleExpand: false,
      expanded: [],
      moduleName: "Products",
      modulePermission: [],
      search: "",
      products: [],
      dialogDelete: false,
      deleteRecord: "",
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      loading: true,
      numbers: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      isFlashMessage: false,
      flashMessage: "",
      prevRoute: null
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px"
        },
        { sortable: false, text: "Name", value: "name", width: "250px" },
        {
          text: "ABV%",
          value: "alcohol_percentage",
          width: "100px"
        },
        {
          text: "Brand",
          value: "brand_name",
          width: "150px"
        },
        {
          sortable: false,
          text: "Category",
          value: "category_name",
          width: "150px"
        },
        {
          text: "Sub Category",
          value: "sub_category_name",
          width: "180px"
        },
        {
          sortable: false,
          text: "Description",
          value: "description",
          width: "250px"
        }
      ];
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    getProducts() {
      if (this.search == "") {
        this.search = null;
      }

      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "product/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then(response => {
          if (response.status == 200) {
            this.products = response.data.products.data;
            this.loading = false;
            this.totalPages = response.data.products.last_page;
            this.startRecord = response.data.products.from;
            this.endRecord = response.data.products.to;
            this.totalRecord = response.data.products.total;
            this.numbers = [];
            for (
              let num = response.data.products.from;
              num <= response.data.products.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getProducts();
    },
    deleteProduct(data) {
      if (data.product_variants != null && data.product_variants.length > 0) {
        this.flashMessage = "You can not delete product having variants!";
        this.isFlashMessage = true;
      } else {
        this.dialogDelete = true;
        this.deleteRecord = data._id;
      }
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "product/delete/" +
            this.deleteRecord
        )
        .then(response => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getProducts();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      // sessionStorage.setItem('currentPage', value)
      this.page = value;
      sessionStorage.setItem("currentPage", this.page);
      this.getProducts();
    }
  },
  mounted() {
    // if (sessionStorage.getItem('currentPage') != null) {
    //   this.page = sessionStorage.getItem('currentPage')
    // }
    this.prevRoute = this.prevRoute != null ? this.prevRoute.name : null;
    if (this.prevRoute == "View Product") {
      // Retrieve the current page from sessionStorage
      const storedPage = sessionStorage.getItem("currentPage");
      if (storedPage != null) {
        // Use the stored page or fallback to the default page (this.page)
        const currentPage = storedPage ? parseInt(storedPage) : this.page;
        this.page = currentPage;
      }
    }

    this.getProducts();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  }
};
</script>
